import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Container } from 'layouts';
import Helmet from 'react-helmet';
import Constants from '../../static/Constants';
import { css } from '@emotion/react';
import { useTranslation } from 'react-i18next';
import getHeaderLInks from '../shared/headerLinks';
import styles from '../styles/home';

const { ParagraphContent } = styles;

const container = css`
  padding-top: 2vh;
  min-height: 70vh;
`;

const About = ({ ...props }) => {
  const { t } = useTranslation();
  const { pageContext } = props;

  const data = pageContext.pageData ? pageContext.pageData : null;

  if (!data) {
    return;
  }

  const programLink = pageContext.programUrl;

  const headerLinks = getHeaderLInks('', programLink, data.noLinks);

  const { pageHeader, headerLogo, headerLogoScroll, programName } =
    (data || {}).node || {};

  const logos = pageContext.logos || [];
  const footerLogos = pageContext.footerLogos || [];

  const footerLinks = [
    {
      name: 'FAQ',
      link: programLink + '/faq',
    },
    {
      name: 'About',
      link: programLink + '/about',
    },
    {
      name: 'Contact Us',
      link: programLink + '/contactUs',
    },
    {
      name: 'Disclaimer',
      link: programLink + '/disclaimer',
    },
  ];

  const helmetLink = `${Constants.appUrl}/about`;
  return (
    <Layout
      programLink={programLink}
      title={t(`${programName || ''} FAQ`)}
      headerLinks={headerLinks}
      footerLinks={footerLinks}
      logos={logos}
      footerLogos={footerLogos}
      pageHeader={pageHeader}
      headerLogo={headerLogo}
      headerLogoScroll={headerLogoScroll}
      imageName={'question-mark.jpg'}
      contentAlignmentStyle={'textCenter'}
    >
      <Helmet>
        <meta charSet="utf-8" />
        <title>{t('Investment Promotion Portal FAQ')}</title>
        <link rel="canonical" href={helmetLink} />
      </Helmet>
      <Container css={container}>
        <span css={ParagraphContent}>
          <h2>Frequently Asked Questions</h2>
          <p>
            <b>1. Does UNIDO charge any fees?</b>
          </p>
          <p>
            UNIDO does not charge any fee. If you have received a solicitation
            for the payment of a fee, please disregard it.
          </p>
        </span>
      </Container>
    </Layout>
  );
};

export default About;

About.propTypes = {
  center: PropTypes.object,
  pageContext: PropTypes.object,
  location: PropTypes.object,
};
